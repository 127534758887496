import React, { createContext, useState } from "react";
import { 
    getAuth, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    updateProfile, 
    onAuthStateChanged, 
    signOut, 
    deleteUser, 
    sendPasswordResetEmail, 
    confirmPasswordReset, 
    checkActionCode, 
    sendSignInLinkToEmail, 
    sendEmailVerification, 
    applyActionCode, 
    FacebookAuthProvider, 
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";
import { app } from "../services/firebase";

const AuthContext = createContext(null);
const providerFacebook = new FacebookAuthProvider();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [validaUsuarioLogado, setValidaUsuarioLogado] = useState(false);
    const [msgDezoitoAnos, setMsgDezoitoAnos] = useState(true);

    const auth = getAuth();

    if(!msgDezoitoAnos){
        onAuthStateChanged(auth, (userFirebase) => {
            if (userFirebase && !user && userFirebase.emailVerified) {
                setUser(userFirebase)
            }
            setValidaUsuarioLogado(false);
        });
    }

    function atualizarMensagem(novoValor){
        try {
            if (msgDezoitoAnos !== novoValor) {
                setMsgDezoitoAnos(novoValor);
            }
        } catch (error) {
            console.log("ERRO - ", error);
        }
    };
    

    const logarUsuario = async (email, senha) => {

        let response = {};
        await signInWithEmailAndPassword(auth, email, senha)
        .then((userCredential) => {
            
            const user = userCredential.user;
            if(user.emailVerified){
                setUser(user);
                response =  {
                    success: true
                };
            }else{
                enviaEmailVerificacao(user);

                response =  {
                    success: false,
                    errorCode: "auth/unverified-email",
                    errorMessage: "E-mail não foi verificado. Enviamos o e-mail novamente!"
                };
            }

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("logarUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    const logOut = async () => {
        
        auth.signOut().then(function () {
            setUser(null);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("logOut - ", errorCode, " - ", errorMessage);
        });
        

    }

    const cadastroUsuario = async (email, senha, nomeCompleto) => {

        let response = {};
        // const auth = await getAuth();
        await createUserWithEmailAndPassword(auth, email, senha)
          .then((userCredential) => {
            
            updateProfile(auth.currentUser, {
                displayName: nomeCompleto
            });

            response =  {
                success: true,
                data: userCredential.user
            };
          })
          .catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("cadastroUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
          });

          return response;
    }

    const enviaEmailVerificacao = async (user) => {

        let response = {};
        await sendEmailVerification(user).then(() => {
            response =  {
                success: true
            };
          })
          .catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("enviaEmailVerificacao - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
          });

          return response;
    }

    const aplicaValidacaoCodigo = async (oobCode) => {

        console.log("aplicaValidacaoCodigo - ", oobCode);
        

        let response = {};
        await applyActionCode(auth, oobCode).then(() => {
            response =  {
                success: true
            };
          })
          .catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("aplicaValidacaoCodigo - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
          });

          return response;
    }

    const excluirUsuario = async () => {

        let response = {};

        const auth = getAuth();
        const userAutenticado = auth.currentUser;

        await deleteUser(userAutenticado).then(() => {
            setUser(null);

            response =  {
                success: true
            };
        }).catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("excluirUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    const enviaEmailResetaSenha = async ( email ) => {

        console.log("enviaEmailResetaSenha");

        const actionCodeSettings = {
            url:"https://degusta-facil-74d8d.firebaseapp.com/?email="+email,
            handleCodeInApp: true
        };

        let response = {};
        await sendPasswordResetEmail(auth, email, actionCodeSettings)
        .then(() => {
            response =  {
                success: true
            };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("enviaEmailResetaSenha - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    const enviaResetaSenha = async ( novaSenha, oobCode ) => {
        let response = {};
        await confirmPasswordReset(auth, oobCode, novaSenha)
        .then(() => {
            response =  {
                success: true
            };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("enviaResetaSenha - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    const loginComFacebook = async () => {
        let response = {};
        await signInWithPopup(auth, providerFacebook)
        .then((result) => {

            // The signed-in user info.
            const user = result.user;

            setUser(user)

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

            // IdP data available using getAdditionalUserInfo(result)
            // ...
            
            response =  {
                success: true
            };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("loginComFacebook - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }
    
    const loginComGoogle = async () => {

        const providerGoogle = new GoogleAuthProvider();
        let response = {};
        await signInWithPopup(auth, providerGoogle)
        .then((result) => {

            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            
            response =  {
                success: true
            };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("loginComGoogle - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    return (
        <AuthContext.Provider value={{ 
            signed: !!user, 
            logarUsuario, 
            logOut, 
            cadastroUsuario, 
            excluirUsuario, 
            user: user, 
            validaUsuarioLogado, 
            msgDezoitoAnos, 
            atualizarMensagem, 
            enviaEmailResetaSenha, 
            enviaResetaSenha, 
            enviaEmailVerificacao, 
            aplicaValidacaoCodigo,
            loginComFacebook,
            loginComGoogle
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;