import { useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDot } from 'react-loading-indicators';

import './login.css';

import iconeBordo from '../../assets/images/iconeBordo.png';

import InputText from '../../components/inputText';
import InputSenha from '../../components/inputSenha';
import Botao from '../../components/botao';
import BotaoRedeSocial from '../../components/botaoRedeSocial';

import AuthContext from '../../context/auth';

function Login() {

    const { logarUsuario, loginComFacebook, loginComGoogle } = useContext(AuthContext);
    const navigate = useNavigate();
    let location = useLocation();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [msgErroPreenchimento, setMsgErroPreenchimento] = useState("");
    const [carregandoClickBotao, setCarregandoClickBotao] = useState(false);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        setSenha(event.target.value);
    }

    const enviaDadosLogin = async () => {
        setCarregandoClickBotao(true);
        const response = await logarUsuario(email, senha);

        if(!response.success){
            if(response.errorCode == "auth/invalid-email"){
                setMsgErroPreenchimento("E-mail inválido.");
            }

            if(response.errorCode == "auth/missing-password"){
                setMsgErroPreenchimento("Senha inválida.");
            }

            if(response.errorCode == "auth/user-not-found"){
                setMsgErroPreenchimento("Usuário não encontrado.");
            }
            
            if(response.errorCode == "auth/invalid-credential"){
                setMsgErroPreenchimento("E-mail ou senha inválida.");
            }

            if(response.errorCode == "auth/unverified-email"){
                setMsgErroPreenchimento(response.errorMessage);
            }
            
            setCarregandoClickBotao(false);
            return;
        }

        if(location?.state){
            setCarregandoClickBotao(false);
            navigate('/', { state: location.state})
            return;
        }

        setCarregandoClickBotao(false);
        navigate('/')
    }

    // Função que será chamada ao pressionar Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enviaDadosLogin();
        }
    };

    const loginComPlataforma = async (plataforma) => {

        let responsePlataforma;

        if(plataforma == 'google'){
            const response = await loginComGoogle();
            responsePlataforma = response;
        }

        if(plataforma == 'facebook'){
            const response = await loginComFacebook();
            responsePlataforma = response;
        }

        if(responsePlataforma.success){
            if(location?.state){
                setCarregandoClickBotao(false);
                navigate('/', { state: location.state})
                return;
            }
    
            setCarregandoClickBotao(false);
            navigate('/')
        }
    }

    return (
        <div className='loginDiv'>
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />
                <div style={{flexDirection: "row", display: 'flex'}}>
                    <BotaoRedeSocial
                        google={true}
                        onClick={() => loginComPlataforma("google")}
                    />
                    <BotaoRedeSocial
                        google={false}
                        onClick={() => loginComPlataforma("facebook")}
                    />
                </div>
                <InputText value={email} onChange={handleEmail} placeholder={"E-mail"} />
                <InputSenha 
                    value={senha} 
                    onChange={handleSenha} 
                    placeholder={"Senha"}
                    onKeyDown={handleKeyDown}
                />
                <div className="txtAbaixoBotoes" style={{marginTop: 0, marginBottom: 10}} onClick={() => {
                    navigate('../resetaSenha')
                }}>
                    <span>Esqueci minha senha!</span>
                </div>
                <div className="txtPreenchimentoIncorreto">
                    <span style={{fontSize: 20}}>{msgErroPreenchimento}</span>
                </div>
                {carregandoClickBotao 
                ? <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" speedPlus={2}/>
                : <Botao
                    colorButton={"#1E1F20"}
                    onClick = {enviaDadosLogin}
                    textoBotao = {'Entrar'}
                />
                }
                <div className="txtAbaixoBotoes" onClick={() => {
                    
                    if(location?.state){
                        navigate('../cadastro', { state: location.state})
                        return;
                    }

                    navigate('../cadastro')
                }}>
                    <span>Ainda não tem uma conta ? Crie agora!</span>
                </div>
            </div>
        </div>
    );
}

export default Login;
