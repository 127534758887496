import React, { useState } from 'react';
import './modalCelular.module.css';
import InputMask from 'react-input-mask';

import InputText from '../inputText';
import BotaoLogo from '../botaoLogo';
import { editaUsuarioDB } from '../../services/authFirestore';
import { useEffect } from 'react';

export default function ModalCelular (params) {

    const [celular, setCelular] = useState("");
    const [avisaCelular, setAvisaCelular] = useState(false);
    const [mask, setMask] = useState("(99) 99999-9999");

    const handleCelular = (event) => {
        setCelular(event.target.value);
    }

    const salvarCelular = async () => {
        setAvisaCelular(false);

        let celularAux = celular.replace(/\D/g, '');

        if(!celularAux || (celularAux.toString().length < 10 || celularAux.toString().length > 12)){
            setAvisaCelular(true);
            return;
        }

        const responseEditaUsuario = await editaUsuarioDB(params.idUsuario, {celular: celularAux});

        if(!responseEditaUsuario.success){
            alert("Celular não foi cadastrado! Tente novamente...")
            return;
        }

        params.onClick();
    }

    return(
        <div className='containerModal'>
            <div className='modal'>
                <div style={{justifyContent: "space-between", display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10}}>
                    <h4>Cadastro celular</h4>
                    <h2 onClick={() => params.onClick()} style={{color: "grey", cursor: "pointer"}}>x</h2>
                </div>
                <InputMask
                    mask={mask}
                    value={celular} onChange={handleCelular} placeholder={"(XX) XXXXX-XXXX"}
                    style={{
                        width:  330, 
                        height: 30,
                        borderRadius: 10,
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingLeft: 10,
                        fontSize: 22,
                        backgroundColor: 'black',
                        opacity: 0.9,
                        color: 'white',
                        marginBottom: 14, 
                        borderBottomWidth: avisaCelular ? 3 : 0,
                        borderBottomColor: 'red',
                        borderEndEndRadius: avisaCelular && 0,
                        borderEndStartRadius: avisaCelular && 0}}
                    onBlur={e => {
                        if (e.target.value.replace("_", "").length === 14) {
                            setMask("(99) 9999-9999");
                        }
                    }}
                    onFocus={e => {
                        if (e.target.value.replace("_", "").length === 14) {
                            setMask("(99) 99999-9999");
                        }
                    }}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                <div 
                    style={{backgroundColor: "#690B23", width: 310, alignSelf: 'center'}}
                    className='containerBotao' 
                    onClick={salvarCelular}
                >
                    <span style={{backgroundColor: "#690B23"}} className='txtBotao'>Cadastrar!</span>
                </div>
            </div>
        </div>
    )
}