import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThreeDot } from 'react-loading-indicators';
import './cadastro.css';

import iconeBordo from '../../assets/images/iconeBordo.png';

import InputText from '../../components/inputText';
import InputSenha from '../../components/inputSenha';
import Botao from '../../components/botao';
import BotaoRedeSocial from '../../components/botaoRedeSocial';

import { IsEmail, IsSenhaForte } from '../../services/validadores';
import { criarUsuarioDB } from '../../services/authFirestore';

import AuthContext from '../../context/auth';

function Cadastro() {

    const navigate = useNavigate();
    let location = useLocation();

    const { cadastroUsuario, excluirUsuario, enviaEmailVerificacao, loginComGoogle, loginComFacebook } = useContext(AuthContext);

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
    const [msgErroPreenchimento, setMsgErroPreenchimento] = useState("");
    const [necessidadesSenha, setNecessidadesSenha] = useState({});
    const [carregandoClickBotao, setCarregandoClickBotao] = useState(false);
    const [mostraMsgEmailVerificacao, setMostraMsgEmailVerificacao] = useState(false);

    const handleNome = (event) => {
        setNome(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        setSenha(event.target.value);

        let responseSenha = IsSenhaForte(event.target.value);

        if(!responseSenha.sucesso){
            setNecessidadesSenha(responseSenha);
            return;
        }

    }

    const handleConfirmacaoSenha = (event) => {
        setConfirmacaoSenha(event.target.value);
    }

    const cadastrarUsuario = async () => {
        setCarregandoClickBotao(true);
        setMsgErroPreenchimento("");

        if(!IsEmail(email)){
            setMsgErroPreenchimento("E-mail inválido.");
            setCarregandoClickBotao(false);
            return;
        }

        if(senha != confirmacaoSenha){
            setMsgErroPreenchimento("Senhas diferentes.");
            setCarregandoClickBotao(false);
            return;
        }

        if(
            !necessidadesSenha?.digito ||
            !necessidadesSenha?.minuscula ||
            !necessidadesSenha?.maiuscula ||
            !necessidadesSenha?.oitoCaracteres
        ){ 
            setCarregandoClickBotao(false);
            return; 
        }

        const response = await cadastroUsuario(email, senha, nome);

        if(!response.success){
            if(response.errorCode == "auth/email-already-exists" || response.errorCode == "auth/email-already-in-use"){
                console.log("E-mail já cadastrado.")
                setMsgErroPreenchimento("E-mail já cadastrado.");
            }
            setCarregandoClickBotao(false);
            return;
        }

        const responseDB = await criarUsuarioDB({
            nome: nome,
            email: email,
            id: response.data.uid
        });

        if(!responseDB.success){
            excluirUsuario();
            setCarregandoClickBotao(false);
            return;
        }
        
        await enviaEmailVerificacao(response.data);

        setMostraMsgEmailVerificacao(true);
    }

    // Função que será chamada ao pressionar Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            cadastrarUsuario();
        }
    };

    if(mostraMsgEmailVerificacao){
        return (
            <div className='cadastroDiv'>
                <div className='box'>
                    <img 
                        className="imagemIcone"
                        src={iconeBordo} 
                        alt="Icone"
                    />
                    <div className="txtAbaixoBotoes" style={{marginBottom: 20}}>
                        <p style={{fontSize: 22, textAlign: 'center'}}>{"Enviamos um e-mail de confirmação,"}</p> 
                        <p style={{fontSize: 22, textAlign: 'center'}}>{"acesse o link enviado no e-mail cadastrado."}</p>
                    </div>
                    <Botao
                        colorButton={"#1E1F20"}
                        onClick = {() => {
                            if(location?.state){
                                setCarregandoClickBotao(false);
                                navigate('../login', { state: location?.state })
                                return;
                            }

                            setCarregandoClickBotao(false);
                            navigate('../login');
                        }}
                        textoBotao = {'Confirmar'}
                    />
                </div>
            </div>
        );
    }

    const loginComPlataforma = async (plataforma) => {

        let responsePlataforma;

        if(plataforma == 'google'){
            const response = await loginComGoogle();
            responsePlataforma = response;
        }

        if(plataforma == 'facebook'){
            const response = await loginComFacebook();
            responsePlataforma = response;
        }

        if(responsePlataforma.success){
            if(location?.state){
                setCarregandoClickBotao(false);
                navigate('/', { state: location.state})
                return;
            }
    
            setCarregandoClickBotao(false);
            navigate('/')
        }
    }

    return (
        <div className='cadastroDiv'>
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />
                <div style={{flexDirection: "row", display: 'flex'}}>
                    <BotaoRedeSocial
                        google={true}
                        onClick={() => loginComPlataforma("google")}
                    />
                    <BotaoRedeSocial
                        google={false}
                        onClick={() => loginComPlataforma("facebook")}
                    />
                </div>
                <InputText value={nome} onChange={handleNome} placeholder={"Nome completo"} />
                <InputText value={email} onChange={handleEmail} placeholder={"E-mail"} />
                <InputSenha value={senha} onChange={handleSenha} placeholder={"Senha"}/>
                <InputSenha 
                    value={confirmacaoSenha} 
                    onChange={handleConfirmacaoSenha} 
                    placeholder={"Confirmação de senha"}
                    onKeyDown={handleKeyDown}
                />
                {senha.length > 0 &&
                    <>
                    {!necessidadesSenha?.digito &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <span>Senha deve conter ao menos um número.</span>
                        </div>
                    }
                    {!necessidadesSenha?.minuscula &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <span>Senha deve conter ao menos uma letra Minúscula.</span>
                        </div>
                    }
                    {!necessidadesSenha?.maiuscula &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <span>Senha deve conter ao menos uma letra Maiúscula.</span>
                        </div>
                    }
                    {!necessidadesSenha?.oitoCaracteres &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <span>Senha deve conter ao menos 8 caracteres.</span>
                        </div>
                    }
                    </>
                }
                <div className="txtPreenchimentoIncorreto">
                    <span>{msgErroPreenchimento}</span>
                </div>
                {carregandoClickBotao 
                ?   <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" speedPlus={2}/>
                :   <Botao
                        colorButton={"#1E1F20"}
                        onClick = {cadastrarUsuario}
                        textoBotao = {'Cadastrar'}
                    />
                }
                <div className="txtAbaixoBotoes" onClick={() => navigate('../login')}>
                    <span>Já tem uma conta ? Entre agora!</span>
                </div>
            </div>
        </div>
    );
}

export default Cadastro;
