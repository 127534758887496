import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './excluirDados.module.css';

import iconePreto from '../../assets/images/iconePreto.png';

import BotaoCancelar from '../../components/botaoCancelar';
import TextoMeses from '../../components/textoMeses';
import { getPortalUrl } from '../../services/startSubscription';
import AuthContext from '../../context/auth';

function ExcluirDados() {
    const { user, excluirUsuario } = useContext(AuthContext);

    const navigate = useNavigate();
    let location = useLocation();    

    return (
        <div className={styles.bodyCancelando}>
            <img 
                className={styles.imagemIcone}
                src={iconePreto} 
                alt="Icone"
            />
            <div>
                <h3>{location.state?.nivelAssinaturaUsuario}</h3>
                <h2>{user?.displayName ? user?.displayName : "-"}</h2>
            </div>

            <div>
                <h1>Realmente deseja excluir seus dados ?</h1>
            </div>
            <BotaoCancelar
                onClick={async () => {
                    await excluirUsuario();
                }}
                textBotao={"Confirmar exclusão"}
            />
        </div>
    );
}

export default ExcluirDados;
