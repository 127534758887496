import './botaoRedeSocial.css';
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";

export default function botaoRedeSocial(params) {
    return(
        <div 
            {...params}
            className='containerBotaoLogoRedeSocial' 
            onClick={() => {
                params.onClick();
            }}
        >
            {params.google ?
                <FcGoogle 
                    size={40}
                />
            :
                <FaFacebook 
                    color='#3B5998'
                    size={40}
                />
            }
        </div>
    )
}